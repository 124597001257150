(() => {
  // ns-params:@params
  var lg = 1200;

  // <stdin>
  function animate() {
    const header = document.querySelector(".header");
    const mediaRule = `(min-width: ${lg}px)`;
    const scrollers = gsap.utils.toArray(".st-scroller");
    const whiteSections = document.querySelectorAll(".bg-white");
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
    if (document.body.dataset.enableSmoothScroll === "true") {
      window.smoother = ScrollSmoother.create({
        smooth: 0.5,
        effects: true
      });
    }
    function changeHeaderOnWhiteBg(params) {
      whiteSections.forEach((section) => {
        gsap.from(header, {
          ease: "none",
          scrollTrigger: {
            toggleClass: { targets: header, className: "header--blue" },
            trigger: section,
            start: "top top",
            end: "bottom top",
            onToggle: ({ isActive }) => {
              Alpine.$data(document.body).isHeaderBlue = isActive;
            }
          }
        });
      });
    }
    ScrollTrigger.create({
      trigger: header,
      toggleActions: "play pause play reverse",
      toggleClass: "header--top"
    });
    ScrollTrigger.matchMedia({
      [mediaRule]: function() {
        gsap.to(".main-menu ul", {
          opacity: 1,
          visibility: "visible",
          ease: "none",
          duration: 0.2,
          delay: 0.2,
          scrollTrigger: {
            toggleActions: "play pause play reverse"
          }
        });
        gsap.to(".main-menu__request", {
          opacity: 0,
          visibility: "hidden",
          duration: 0.2,
          ease: "none",
          scrollTrigger: {
            toggleActions: "play pause resume reverse"
          }
        });
        function overlaySections() {
          scrollers.forEach((scroller) => {
            const panels = scroller.querySelectorAll(".st-panel");
            panels.forEach((panel, i) => {
              const nextSibling = panel.parentElement.nextElementSibling;
              const nextPanel = panels[i + 1];
              gsap.from(panel, {
                scrollTrigger: {
                  trigger: nextPanel || nextSibling || panel,
                  start: nextPanel || nextSibling ? "top bottom" : "top top",
                  end: nextPanel || nextSibling ? "top top" : "bottom bottom",
                  pin: panel,
                  pinSpacing: false
                }
              });
            });
          });
        }
      }
    });
    function vanishEffect(params) {
      const vanishBoxes = gsap.utils.toArray(".js-vanish-box");
      vanishBoxes.forEach((box, i) => {
        box.classList.add("vanish-box");
        gsap.to(box, {
          "--height": () => box.offsetHeight + header.offsetHeight,
          ease: "none",
          scrollTrigger: {
            trigger: box,
            start: () => `top ${header.offsetHeight}px`,
            end: "bottom top",
            scrub: true
          }
        });
      });
    }
    const numbers = gsap.utils.toArray("[data-number]");
    numbers.forEach((number, i) => {
      const int = parseInt(number.dataset.number);
      number.innerText = "0";
      gsap.to(number, {
        innerText: int,
        duration: 0.8,
        ease: "none",
        snap: {
          innerText: 1
        },
        scrollTrigger: {
          trigger: number
        }
      });
    });
  }
  if (localStorage.getItem("disableAnimation") !== "true") {
    animate();
  } else {
    document.querySelector(".main-menu")?.classList.add("main-menu--disable-animation");
  }
})();

;
(() => {
  // ns-params:@params
  var lg = 1200;
  var xs = 576;

  // <stdin>
  var videoEls = document.querySelectorAll(".hero-video");
  var sources = {
    mobile: "/media/uploads/BG_pixel_576x660.webm",
    tablet: "/media/uploads/BG_pixel_1200x668.webm",
    desktop: "/media/uploads/BG_pixel_1920x1020.webm"
  };
  var isTablet = window.matchMedia(`(min-width: ${xs}px) and (max-width: ${lg}px)`);
  var isDesktop = window.matchMedia(`(min-width: ${lg}px)`);
  videoEls.forEach((video) => {
    const souceEl = video.querySelector("source");
    if (isTablet.matches) {
      souceEl.src = sources.tablet;
    } else if (isDesktop.matches) {
      souceEl.src = sources.desktop;
    } else {
      souceEl.src = sources.mobile;
    }
    video.load();
    video.play();
  });
})();

;
(() => {
  // <stdin>
  var isScriptLoaded = false;
  window.loadPerformanceVideo = function() {
    if (!isScriptLoaded) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      let player;
      const playerEl = document.querySelector("#player");
      window.onYouTubeIframeAPIReady = function() {
        player = new YT.Player(playerEl, {
          videoId: playerEl.dataset.id,
          playerVars: {
            autoplay: 0,
            playsinline: 1,
            modestbranding: 1,
            rel: 0
          },
          events: {
            onReady: onPlayerReady
          }
        });
        function onPlayerReady() {
          player.playVideo();
        }
        const closeButton = document.querySelector(".video__player button");
        closeButton.addEventListener("click", () => {
          player.pauseVideo();
        });
      };
      isScriptLoaded = true;
    }
  };
})();

;
(() => {
  // <stdin>
  var connectForm = document.querySelector(".connect form");
  if (connectForm) {
    connectForm.addEventListener("submit", (e) => {
      e.preventDefault();
      const url = "/contact-us?";
      const formData = new FormData(connectForm);
      const params = new URLSearchParams(formData).toString();
      window.location.href = url + params;
    });
  }
})();
